.sortable-section {
  position: relative;
}

.section-tooltip {
  opacity: 0;
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  top: 0;
  left: 0;
  font-size: var(--font-size-p3);
  z-index: 1;
}

.sortable-section:hover .section-tooltip {
  opacity: 0.6;
}

.section-container.sortable > .section-view {
  margin-bottom: 0;
  margin-left: 20px;
}

.section-container.sortable > div:first-child,
.section-container.sortable > div:last-child {
  margin-bottom: 20px;
}

.section-container.sortable {
  cursor: grab;
}

.section-popup .form-control-container {
  margin-bottom: 40px;
}

.section-popup .horizontal-1 {
  margin-bottom: 40px;
}

.section-popup .horizontal-1 .form-control-container {
  margin-bottom: 0px;
}

.section-popup .address-sync-checkbox {
  margin-bottom: 40px;
}

.section-view-wrapper {
  position: relative;
  padding-bottom: 30px;
}
.toggle-more {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 5%;
  color: var(--blue-color);
  font-size: var(--font-size-h3);
}

.collapsed-section {
  max-height: 130px;
  overflow-y: hidden;
}
