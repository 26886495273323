.section-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color-light);
}

.section-header-Collapse {
  cursor: pointer;
}

.section-header-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}

.section-header-control {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}
